import React from 'react';
import { Link } from 'react-router-dom';
import './QuestionDropDown.css';
import { ReactComponent as QuestionIcon } from '../../../assets/images/svg/questions.svg';
import { ReactComponent as CodeIcon } from '../../../assets/images/svg/developer-center.svg';
import { ReactComponent as ChatIcon } from '../../../assets/images/svg/contact-support.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/svg/next.svg';
import { ReactComponent as AssignmentIcon } from '../../../assets/images/svg/support-docs.svg';

const QuestionDropDown = () => {
  return (
    <div className="profile-dropdown">
      <div className="dropdown">
        <div className="dropdown-btn">
          <QuestionIcon
            className="profile-icon"
            fontSize="large"
          />
        </div>
        <div className="dropdown-content">
          <Link
            className="d-link"
            target="_blank"
            to={{ pathname: 'https://docs.optty.com/' }}
          >
            <AssignmentIcon /> <span>Support Docs</span> <ArrowRightIcon />
          </Link>
          <Link
            className="d-link"
            target="_blank"
            to={{ pathname: 'https://docs.optty.com/' }}
          >
            <CodeIcon /> <span>Developer Center</span> <ArrowRightIcon />
          </Link>
          <Link
            className="d-link"
            target="_blank"
            to={{ pathname: 'https://www.optty.com/technical-support' }}
          >
            <ChatIcon /> <span>Contact Support</span> <ArrowRightIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuestionDropDown;
