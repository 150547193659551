import { APP_CACHE_STORAGE_DRIVER } from '../config';

export default {
  getToken() {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.getItem('access_token');
  },
  setToken(token: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.setItem('token', token);
  },
  getItem(key: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.getItem(key);
  },
  setItem(key: string, value: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.setItem(key, value);
  },
  removeItem(key: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.removeItem(key);
  },
};
