import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { notifyError, notifyOk as notify } from 'libs/utils/Toaster';
import { composeClasses } from 'libs/utils';
import { ArrowRight } from '@material-ui/icons';
import { apiAuth } from '../libs/utils/api';
import { Button, SingleLineTextInput, FormControlBox } from './FormElements';
import './MerchantForm.css';
import { ButtonDisplay, useButton } from './FormElements/Button';
import { InputStyling } from './FormElements/SingleLineTextInput';
import Feature from './Feature';
import SignUpSupport, { ContentOptions } from './StaticContent/SignUpSupport';

const ManageMerchantForm = (props: any) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    apiKey: props.selectedBnpl?.apiKey || '',
    apiSecret: props.selectedBnpl?.apiSecret || '',
    recordId: props.selectedBnpl?.recordId,
    errorMessage: '',
    success: false,
    touched: false,
  });
  const { isButtonLoading, setButtonState } = useButton();
  const mountedRef = useRef(true);

  useEffect(() => {
    const { selectedBnpl } = props;

    setState((prevState) => ({
      ...prevState,
      apiKey: selectedBnpl.apiKey ?? '**********',
      apiSecret: selectedBnpl.apiSecret ?? '**********',
      touched: false,
      errorMessage: '',
    }));
    return () => {
      mountedRef.current = false;
    };
  }, [props]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      touched: true,
      errorMessage: '',
    }));
  };

  const isFormValid = () => {
    const { apiKey, apiSecret, errorMessage } = state;
    return (
      apiKey !== ''
       && apiSecret !== ''
       && errorMessage === ''
       && !isButtonLoading
       && !apiKey.includes('*')
       && !apiSecret.includes('*')
    );
  };

  const handleAPIUpdate = async (e: any) => {
    e.preventDefault();
    const { apiKey, apiSecret, recordId } = state;
    try {
      // Disable button
      setButtonState(true);
      await apiAuth.put('/merchant/bnplprovider/update', {
        recordId,
        apiKey,
        apiSecret,
        providerName: props.selectedBnpl.providerName,
      });
      if (mountedRef.current) {
        // redirect
        setState((prevState) => ({
          ...prevState,
          success: true,
        }));
        setButtonState(false);
        notify('Provider updated successfully');
      }
    } catch (error) {
      setButtonState(false);
      if (error.response.status === 403) {
        notifyError('BNPL credentials can only be updated when deactivated', {
          toastId: 'not-deactivated',
        }, { autoClose: false });
        setState((prevState) => ({
          ...prevState,
          errorMessage: 'BNPL credentials can only be updated when deactivated',
        }));
      } else {
        notifyError('Invalid API Key or Secret', {
          toastId: 'invalid-key',
        });
        setState((prevState) => ({
          ...prevState,
          errorMessage: 'Invalid API Key or Secret',
        }));
      }
    }
  };

  return props.selectedBnpl ? (
    <div className="content-container">
      <div
        className={composeClasses('shadowed-container', 'credential-container')}
        data-testid="manage-merchant-div"
      >
        <div className="form-title">1. {t('Enter API Key')}</div>
        <form onSubmit={(e) => handleAPIUpdate(e)}>
          <FormControlBox
            className="text-bolder"
            controlClassName=""
            htmlFor="apiKey"
            labelText={t('API Key')}
          >
            <SingleLineTextInput
              data-testid="bnpl-apikey"
              hasError={state.errorMessage !== ''}
              hasSuccess={state.success}
              id="apiKey"
              name="apiKey"
              onChange={(e) => handleChange(e)}
              placeholder={t('Enter API Key')}
              required
              styling={InputStyling.Vanilla}
              type="text"
              value={state.apiKey || ''}
            />
          </FormControlBox>
          <FormControlBox
            className="text-bolder"
            controlClassName=""
            htmlFor="apiSecret"
            labelText={t('Secret')}
          >
            <SingleLineTextInput
              data-testid="bnpl-secret"
              hasError={state.errorMessage !== ''}
              hasSuccess={state.success}
              id="apiSecret"
              name="apiSecret"
              onChange={(e) => handleChange(e)}
              placeholder={t('Enter Secret')}
              required
              styling={InputStyling.Vanilla}
              type="password"
              value={state.apiSecret || ''}
            />
          </FormControlBox>
          <div>
            <div className="manage-form-submit">
              <Button
                disabled={!isFormValid() || !state.touched}
                display={ButtonDisplay.NextButton}
                text={(
                  <>
                    {t('Test Connection')} <ArrowRight />
                  </>
                )}
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
      <Feature name="bnpl-management.manage.api-settings.content.support">
        <SignUpSupport
          selectedBnpl={{
            provider: props.selectedBnpl.providerName,
            currency: props.selectedBnpl.currency,
            imageUrl: props.selectedBnpl.imageUrl,
          }}
          type={ContentOptions.Support}
        />
      </Feature>
    </div>
  ) : null;
};

export default ManageMerchantForm;
